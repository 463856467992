// API
export const POLICY_API = '/policy';
export const POLICY_LOADING = 'POLICY_LOADING';
//ORGANISATION POLICY
export const ADD_ORGANISATION_POLICY = 'ADD_ORGANISATION_POLICY';
export const DELETE_ORGANISATION_POLICY = 'DELETE_ORGANISATION_POLICY';
export const ADD_ORGANISATION_POLICY_BY_ID = 'ADD_ORGANISATION_POLICY_BY_ID';

//APPLICATION POLICY
export const ADD_APPLICATION_POLICY = 'ADD_APPLICATION_POLICY';
export const DELETE_APPLICATION_POLICY = 'DELETE_APPLICATION_POLICY';
export const ADD_APPLICATION_POLICY_BY_ID = 'ADD_APPLICATION_POLICY_BY_ID';

//SPACE POLICY
export const ADD_SPACE_POLICY = 'ADD_SPACE_POLICY';
export const DELETE_SPACE_POLICY = 'DELETE_SPACE_POLICY';
export const ADD_SPACE_POLICY_BY_ID = 'ADD_SPACE_POLICY_BY_ID';
