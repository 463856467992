//Actions
export const ADD_TOKEN = 'ADD_TOKEN';
export const ADD_TOKENS = 'ADD_TOKENS';
export const ADD_TOKENS_REQUEST = 'ADD_TOKENS_REQUEST';
export const SET_TOKENS_LOADING = 'SET_TOKENS_LOADING';
export const STOP_TOKENS_LOADING = 'STOP_TOKENS_LOADING';
export const RESET_TOKENS = 'RESET_TOKENS';

//API
export const TOKENS_API = '/tokens';

//APPLICATION_TOKENS
export const ADD_APPLICATION_TOKENS = 'ADD_APPLICATION_TOKEN';
export const DELETE_APPLICATION_TOKEN = 'DELETE_APPLICATION_TOKEN';

//ORGANISATION_TOKENS
export const ADD_ORGANISATION_TOKENS = 'ADD_ORGANISATION_TOKEN';
export const DELETE_ORGANISATION_TOKEN = 'DELETE_ORGANISATION_TOKEN';

//SPACE_TOKENS
export const ADD_SPACE_TOKENS = 'ADD_SPACE_TOKENS';
export const DELETE_SPACE_TOKEN = 'DELETE_SPACE_TOKEN';
