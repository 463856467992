//Actions
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const ADD_ORGANISATIONS = 'ADD_ORGANISATIONS';
export const RESET_ORGANISATIONS = 'RESET_ORGANISATIONS';
export const SET_ORGANISATIONS_LOADING = 'SET_ORGANISATIONS_LOADING';
export const SET_SELECTED_ORGANISATION = 'SET_SELECTED_ORGANISATION';
export const ADD_ORGANISATION_USERS = 'ADD_ORGANISATION_USERS';
export const ADD_APPLICATION_IDS = 'ADD_APPLICATION_IDS';
export const ADD_ORGANISATION_ROLE = 'ADD_ORGANISATION_ROLE';
export const ADD_ORGANISATION_ROLE_IDS = 'ADD_ORGANISATION_ROLE_IDS';
export const ADD_ORGANISATION_POLICY_IDS = 'ADD_ORGANISATION_POLICY_IDS';
export const ADD_MY_ORGANISATION_ROLE = 'ADD_MY_ORGANISATION_ROLE';
export const ADD_ORGANISATION_TOKEN_IDS = 'ADD_ORGANISATION_TOKEN_IDS';
//API
export const ORGANISATIONS_API = '/organisations';
