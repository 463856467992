//Actions
export const ADD_SPACE = 'ADD_SPACE';
export const ADD_SPACES = 'ADD_SPACES';
export const SET_SPACES_LOADING = 'SET_SPACES_LOADING';
export const RESET_SPACES = 'RESET_SPACES';
export const STOP_SPACES_LOADING = 'STOP_SPACES_LOADING';
export const SET_SELECTED_APP = 'SET_SELECTED_APP';
export const ADD_SPACE_ROLE_IDS = 'ADD_SPACE_ROLE_IDS';
export const ADD_SPACE_POLICY_IDS = 'ADD_SPACE_POLICY_IDS';
export const ADD_SPACE_USERS = 'ADD_SPACE_USERS';
export const ADD_SPACE_TOKEN_IDS = 'ADD_SPACE_TOKEN_IDS';
//API
export const SPACES_API = '/spaces';
