// API
export const ROLES_API = '/roles';

//SPACE ROLES
export const ADD_ORGANISATION_ROLES = 'ADD_ORGANISATION_ROLES';
export const DELETE_ORGANISATION_ROLES = 'DELETE_ORGANISATION_ROLES';
export const ADD_ORGANISATION_ROLE_BY_ID = 'ADD_ORGANISATION_ROLE_BY_ID';
export const ADD_ORGANISATION_ROLE_USERS = 'ADD_ORGANISATION_ROLE_USERS';

//APPLICATION ROLES
export const ADD_APPLICATION_ROLES = 'ADD_APPLICATION_ROLES';
export const DELETE_APPLICATION_ROLES = 'DELETE_APPLICATION_ROLES';
export const ADD_APPLICATION_ROLE_BY_ID = 'ADD_APPLICATION_ROLE_BY_ID';
export const ADD_APPLICATION_ROLE_USERS = 'ADD_APPLICATION_ROLE_USERS';

//SPACE ROLES
export const ADD_SPACE_ROLES = 'ADD_SPACE_ROLES';
export const DELETE_SPACE_ROLES = 'DELETE_SPACE_ROLES';
export const ADD_SPACE_ROLE_BY_ID = 'ADD_SPACE_ROLE_BY_ID';
export const ADD_SPACE_ROLE_USERS = 'ADD_SPACE_ROLE_USERS';

// ACTIONS
export const ROLES_LOADING = 'ROLES_LOADING';
