//Actions
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const ADD_APPLICATIONS = 'ADD_APPLICATIONS';
export const ADD_APPLICATIONS_REQUEST = 'ADD_APPLICATIONS_REQUEST';
export const SET_APPLICATIONS_LOADING = 'SET_APPLICATIONS_LOADING';
export const RESET_APPLICATIONS = 'RESET_APPLICATIONS';
export const ADD_SPACE_IDS = 'ADD_SPACE_IDS';
export const ADD_APPLICATION_ROLE_IDS = 'ADD_APPLICATION_ROLE_IDS';
export const ADD_APPLICATION_POLICY_IDS = 'ADD_APPLICATION_POLICY_IDS';
export const ADD_APPLICATION_TOKEN_IDS = 'ADD_APPLICATION_TOKEN_IDS';

//API
export const APPLICATIONS_API = '/organisations';
export const GET_DEFAULT_APPLICATIONS = 'GET_DEFAULT_APPLICATIONS';
export const SET_DEFAULT_APPLICATION_LOADING = 'SET_DEFAULT_APPLICATION_LOADING';
